import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  currentUserSelector,
  fetchingUserFlagsSelector,
} from "@store/user/user-selector";
import { MemberOverviewPage } from "@pages/profile/member-overview-page";
import { MemberTabs } from "@router/route-params";
import { MemberAccountSecurityPage } from "@pages/profile/member-account-security-page";
import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { fetchCompanyMemberDetails } from "@store/members/members-slice-thunk";
import { useEffect, useMemo } from "react";
import { useCoreApiClient } from "@api/use-core-api-client";
import { fetchingMembersFlagsSelector } from "@store/members/members-selector";
import { companyMemberSelector } from "@store/members/members-selector";
import { resetCompanyMemberGroupsState } from "@store/company-member-groups/company-member-groups-slice";
import { OpenTabEvents } from "@utils/track-event/track-event-list";

/**
 * Content in the member page
 */
export function MemberPage(): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const { memberTabs } = useAppParams();
  const dispatch = useAppDispatch();
  const { companyId, memberId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const companyMember = useAppSelector(companyMemberSelector(memberId));
  const { isFetchingCurrentUser, isFetchingLoggedInUser } = useAppSelector(
    fetchingUserFlagsSelector
  );
  const { isFetchingCompanyMemberDetails } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const isCurrentUser = currentUser?.identity === memberId;

  const isLoading = useMemo(() => {
    return (
      isFetchingCompanyMemberDetails ||
      !companyMember ||
      isFetchingCurrentUser ||
      isFetchingLoggedInUser
    );
  }, [
    companyMember,
    isFetchingCompanyMemberDetails,
    isFetchingCurrentUser,
    isFetchingLoggedInUser,
  ]);

  // Fetches the member details
  useEffect(() => {
    if (companyId && memberId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(
        fetchCompanyMemberDetails({
          coreApiClient,
          companyId,
          identity: memberId,
        })
      );
    }

    return () => {
      /* Clean the store for the company members group */
      dispatch(resetCompanyMemberGroupsState());
    };
  }, [companyId, coreApiClient, dispatch, memberId]);

  // Show not found page if member tab is not valid
  if (!memberTabs || !Object.values(MemberTabs).includes(memberTabs)) {
    return <NotFoundPage />;
  }

  // Show not found page if there is not a valid member object after fetching is complete
  if (!companyMember && !isFetchingCompanyMemberDetails) {
    return <NotFoundPage />;
  }

  return (
    <TabsWithRoutes
      selectedTab={memberTabs}
      dataTestId="MEMBER_PAGE_CONTAINER"
      trackingEventPageName={OpenTabEvents.openMemberAccountTab}
      requiredAttribute={companyMember}
      tabs={[
        {
          label: "Overview",
          route: MemberTabs.overview,
          content: (user) => (
            <MemberOverviewPage
              member={user}
              isLoading={isFetchingCompanyMemberDetails}
            />
          ),
          loadingContent: <MemberOverviewPage member={null} isLoading={true} />,
        },
        {
          label: "Account & Security",
          route: MemberTabs.account,
          isHidden: !isCurrentUser,
          content: (user) => (
            <MemberAccountSecurityPage
              currentUser={currentUser}
              member={user}
              isLoading={isLoading}
            />
          ),
          loadingContent: (
            <MemberAccountSecurityPage
              currentUser={null}
              member={null}
              isLoading={true}
            />
          ),
        },
      ]}
    />
  );
}
